import React from 'react';
import { Link } from 'react-router-dom';

import { urlFor } from '~/sanity/images';
import { shedArticleRoute } from '~/routes';
import { AuthorBlock } from '../components';
import { ArticleBlockCategory } from '~/components/the-shed/components';

import styles from '~/components/the-shed/home-page/featured-article-header.module.scss';

const FeaturedArticleHeader = ({ article = {} }) => {
  const { title, category, heroImage, author, publishedAt, slug, updatedAt } =
    article;

  const desktopSource = urlFor(heroImage).height(640).width(1440).fit('max');
  const mobileSource = urlFor(heroImage).height(336).width(375).fit('max');

  return (
    <section className={styles.heroContainer}>
      <div className={styles.mobileHeroOverlay} />
      <img
        className={styles.heroImage}
        srcSet={`
            ${mobileSource.url()} 375w,
            ${desktopSource.url()}
          `}
        sizes="(max-width: 768px) 375px, 1440px"
        alt={heroImage?.alt || ''}
        width="1440"
        height="640"
      />

      <div className={styles.wrapper}>
        <div className={styles.titleBox}>
          <ArticleBlockCategory
            category={category}
            className={styles.category}
          />
          <Link
            className={styles.titleLink}
            to={shedArticleRoute({
              category: category?.articleCategory.slug,
              slug,
            })}
          >
            <h1 className={styles.title}>{title}</h1>
          </Link>
          <div className={styles.authorWrapper}>
            <AuthorBlock
              author={author}
              publishedAt={publishedAt}
              updatedAt={updatedAt}
              variant="featured-article"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export { FeaturedArticleHeader };
