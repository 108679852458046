import React from 'react';
import cx from 'classnames';

import { shedArticleRoute } from '~/routes';
import {
  ArticleBlock,
  AuthorBlock,
  DashHeader,
  ArticleBlockImage,
  ArticleBlockCategory,
  ArticleBlockTitle,
  ArticleBlockSummary,
} from '~/components/the-shed/components';

import styles from '~/components/the-shed/components/article-quintet.module.scss';

const ArticleQuintet = ({ title, articles = [] }) => {
  return (
    <section>
      <h2 className={cx(styles.sectionHeader, styles.mobileOnly)}>{title}</h2>
      <div className={styles.articleQuintet}>
        <span className={cx(styles.header, styles.desktopOnly)}>
          <DashHeader title={title} />
        </span>
        {articles.map((article, i) => {
          const isMainArticle = i === 0;
          const {
            title,
            shortDescription,
            category,
            thumbnailImage,
            thumbnailImagePreview,
            author,
            publishedAt,
            slug,
            updatedAt,
          } = article;

          return (
            <ArticleBlock
              key={article.title}
              className={cx({
                [styles.mainArticle]: isMainArticle,
                [styles.secondaryArticle]: !isMainArticle,
              })}
            >
              <ArticleBlockImage
                className={styles.articleImage}
                image={thumbnailImage}
                imagePreview={thumbnailImagePreview}
                to={shedArticleRoute({
                  category: category?.articleCategory?.slug,
                  slug,
                })}
              />
              <ArticleBlockCategory
                category={category}
                className={styles.articleCategory}
              />
              <ArticleBlockTitle
                className={styles.articleTitle}
                title={title}
                to={shedArticleRoute({
                  category: category?.articleCategory?.slug,
                  slug,
                })}
              />
              {isMainArticle && (
                <ArticleBlockSummary
                  summary={shortDescription}
                  className={styles.shortDescription}
                />
              )}
              <span className={styles.desktopOnly}>
                <AuthorBlock
                  author={author}
                  publishedAt={publishedAt}
                  updatedAt={updatedAt}
                  variant="horizontal-simple"
                />
              </span>
            </ArticleBlock>
          );
        })}
      </div>
    </section>
  );
};

export { ArticleQuintet };
