import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import {
  ArticleBlock,
  AuthorBlock,
  ArticleBlockImage,
  ArticleBlockCategory,
  ArticleBlockTitle,
} from '~/components/the-shed/components';
import { shedArticleRoute, shedCategoryRoute } from '~/routes';
import { urlFor } from '~/sanity/images';

import styles from '~/components/the-shed/components/category-sub-nav.module.scss';

/**
 * Note: A `categoryItem` is an object containing:
 * {
 *   category: ArticleCategory
 * }
 */
const CategorySubNav = ({ categoryItems = [] }) => {
  const [activeCategoryIdx, setActiveCategoryIdx] = useState(0);

  return (
    <section>
      <span className={styles.desktopOnly}>
        <div className={styles.desktopCategoryNav}>
          <div className={styles.desktopMainItem}>Explore by category</div>
          {categoryItems.map((item) => {
            const backgroundImageUrl = urlFor(item.category.thumbnailImage)
              .width(160)
              .fit('max')
              .url();

            return (
              <Link
                key={item.category.slug}
                className={styles.navItemLink}
                to={shedCategoryRoute({ category: item.category.slug })}
              >
                <div
                  style={{ backgroundImage: `url(${backgroundImageUrl})` }}
                  className={styles.desktopNavItemWrapper}
                >
                  <div className={styles.desktopNavItem}>
                    {item.category.name}
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </span>
      <span className={styles.mobileOnly}>
        <div className={styles.mobileCategoryNavHeader}>
          {categoryItems.map((item, i) => {
            const isLastItem = i === categoryItems.length - 1;

            return (
              <Fragment key={item.category.name}>
                <div
                  className={cx(styles.mobileNavItem, styles.mobileNavSpacer)}
                />
                <button
                  className={cx(styles.mobileNavItem, {
                    [styles.activeNavLink]: i === activeCategoryIdx,
                  })}
                  onClick={() => setActiveCategoryIdx(i)}
                >
                  {item.category.name}
                </button>
                {isLastItem && (
                  <div
                    className={cx(styles.mobileNavItem, styles.mobileNavSpacer)}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <div className={styles.mobileCategoryArticles}>
          {categoryItems[
            activeCategoryIdx
          ]?.category?.highlightedArticles?.articles?.map((article) => {
            const articleRoute = shedArticleRoute({
              category: article.category?.articleCategory.slug,
              slug: article.slug,
            });

            return (
              <ArticleBlock key={article.slug}>
                <ArticleBlockImage
                  image={article.thumbnailImage}
                  imagePreview={article.thumbnailImagePreview}
                  to={articleRoute}
                  className={styles.articleBlockImage}
                />
                <ArticleBlockCategory
                  category={article.category}
                  className={styles.articleBlockCategory}
                />
                <ArticleBlockTitle
                  title={article.title}
                  to={articleRoute}
                  className={styles.articleBlockTitle}
                />
                <AuthorBlock
                  author={article.author}
                  publishedAt={article.publishedAt}
                  updatedAt={article.updatedAt}
                  variant="horizontal-simple"
                />
              </ArticleBlock>
            );
          })}
        </div>
        <div className={styles.moreLink}>
          <Link
            to={shedCategoryRoute({
              category: categoryItems[activeCategoryIdx]?.category?.slug,
            })}
          >
            More from {categoryItems[activeCategoryIdx]?.category?.name}
          </Link>
        </div>
      </span>
    </section>
  );
};

export { CategorySubNav };
