import React from 'react';

import { useHomePageData } from '~/components/the-shed/use-shed-data';
import AlertMessage from '~/components/messages/alert-message';
import LoadingIndicator from '~/components/loading-indicator';
import { FeaturedArticleHeader } from '~/components/the-shed/home-page/featured-article-header';
import {
  ArticleGridTrio,
  ArticleQuartet,
  ArticleQuintet,
  ArticleTrio,
  CategorySubNav,
  DashHeader,
  FancyFeaturedArticle,
  SundayGuarantee,
} from '~/components/the-shed/components';
import { useSEO } from '~/hooks/use-seo';

import styles from '~/components/the-shed/home-page/shed-home.module.scss';

const ShedHome = () => {
  const { data, error, isLoading } = useHomePageData();
  useSEO({
    title: data?.seo?.documentTitle,
    description: data?.seo?.documentDescription,
  });

  if (error) {
    return (
      <AlertMessage heading="Error">
        <p>There was a problem while loading. Please try again.</p>
      </AlertMessage>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          height: '100vh',
          margin:
            '-4rem var(--inverse-gutter) var(--inverse-body-padding-bottom)',
          paddingTop: '4rem',
          textAlign: 'center',
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={styles.shedHomePage}>
      <FeaturedArticleHeader article={data?.heroArticle} />
      <div className={styles.quintetSection}>
        <ArticleQuintet
          title={data?.highlight1?.header}
          articles={data?.highlight1?.articles}
        />
      </div>
      <div className={styles.categorySubNavSection}>
        <CategorySubNav categoryItems={data?.subNav?.categories} />
      </div>
      <div className={styles.guaranteeSection}>
        <SundayGuarantee />
      </div>
      <div className={styles.trioSection}>
        <ArticleTrio
          title={data?.highlight2?.header}
          articles={data?.highlight2?.articles}
        />
      </div>
      <div className={styles.quartetSection}>
        <ArticleQuartet
          title={data?.seasonal?.header}
          articles={data?.seasonal?.articles}
        />
      </div>
      <div className={styles.featuredArticleSection}>
        <FancyFeaturedArticle article={data?.featuredArticle?.article} />
      </div>

      <div className={styles.gridSection}>
        <DashHeader title="Recent Articles" />
        <ArticleGridTrio articles={data?.recentArticles} />
      </div>
    </div>
  );
};

export default ShedHome;
